import * as React from 'react'
import {
    contentBlock,
    projectHeading,
} from "./oceano.module.css";
import ExpertBlock from "./expertBlock";
import QuesBlock from "./questionBlock";
import ContactForm from "./contactForm";


const EconomicSection = (props) => {

    return (<>
        <div className={'md:pt-20 pt-10 md:pb-0 pb-10'}>
            <ExpertBlock/>
        </div>
        {/* <div className={projectHeading}>
            <h2>Economic Appeal</h2>
        </div>

        <div className={`${contentBlock}`}>
            <p>
                By investing in Oceano, you will have the opportunity to obtain a 10-year Golden Visa. It is available
                to those who purchase property worth AED 2M (USD 545K) and above. This residency type applies to family
                members and domestic staff, and can be renewed multiple times.
            </p>
            <p>
                Another advantage of Oceano is the opportunity to enjoy lucrative rental income, thanks to its
                convenient location at the waterfront, about 35 minutes away from the international airport. The average
                ROI on Al Marjan Island is 6%, with the starting rental price for a 1-bedroom unit set at AED 30K (USD
                8K) per annum.
            </p>
            <p>
                *The price is valid for 2023
            </p>

        </div> */}

        <QuesBlock/>
        <ContactForm/>

    </>)
}

export default EconomicSection
