import * as React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner_bg from "../../images/oceano/banner02-01.png";
// import Banner_bg from "../../images/oceano/Oceano-1.jpg";
import {
  projectHeader,
  projectHeader_contentHeader,
  projectHeader_imageHeader,
  headerLogo,
  secondImage,
  firstImage,
  headerTitle,
  projectHeading,
  gridTitle,
  gridPrice,
  gridView,
  contentBlock,
  projectHeader_imageHeader2,
  projectHeader_contentHeader2,
  F_D,
  w_100,
  round_right,
  round_left,
  pageWidth,
  overlay,
} from "./oceano.module.css";
import { BsArrowRightCircle } from "react-icons/bs";

import { StaticImage } from "gatsby-plugin-image";

const HeroArea = ({ countryISO, short, symbol }) => {
  return (
    <>
      {/* <div className={`flex ${F_D}`}> */}

      <div className={`relative ${projectHeader_imageHeader}`}>
        <StaticImage
          src={"../../images/oceano/banner02-01.jpg"}
          objectPosition={"85%"}
          alt={"Oceano Banner"}
          style={{ objectFit: "contain", minHeight: "500px" }}
        />
        <div className={`${overlay}`}></div>
        <div className="container">
          <div
            className={`top-0 absolute p-12 flex flex-col justify-center h-full`}
          >
            <div className={`max-w-lg`}>
              <div className="mb-3">
                <div className={secondImage}>
                  <StaticImage
                    src={"../../images/oceano/metro2.png"}
                    alt={"Sobha Hartland"}
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </div>
              {/* <div className={`text-lg text-white text-center md:text-left`}>
                  Similar Properties for Sale in
                </div> */}
              <div>
                <h1
                  className={"md:text-6xl text-bnwdarkgold text-4xl font-bold"}
                >
                  Oceano
                </h1>
                <p className="mt-4 text-white">
                  Oceano is a premium residential development by The Luxe
                  Developers on Al Marjan Island in Ras Al Khaimah. Overlooking
                  the Arabian Gulf, the complex will consist of 2 twin 17-storey
                  towers, which will have free flowing glass facades with
                  minimalist frames.
                </p>
                <div className={"mt-5"}>
                  <button
                    onClick={() => {
                      document.getElementById("formModal").style.display = "block";
                    }}
                    style={{ flex: 1, alignContent: "center" }}
                    className={" text-lg  px-9 bg-bnwdarkgold py-2 text-white"}
                    type="submit"
                  >
                    <span>
                    Download Brochure
                      <BsArrowRightCircle
                        className={
                          "text-lg text-primary-transwhite ml-2 inline"
                        }
                      />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <div className="z-50 top-0 absolute p-4">
            {/* <div className={`pr-5 mr-5 border-r-2 border-white  ${firstImage}`}>
                                    <StaticImage
                                        src={"../../images/oceano/logo2.png"}
                                        alt={"Sobha Hartland"}
                                        style={{objectFit: "contain"}}
                                    />
                                </div> */}
            {/* <div className={secondImage}>
                <StaticImage
                  src={"../../images/oceano/metro2.png"}
                  alt={"Sobha Hartland"}
                  style={{ objectFit: "contain" }}
                />
              </div> */}
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}

      <div className="container px-5">
        <div className={projectHeading}>
          <h2>About Oceano</h2>
        </div>

        <div className={pageWidth}>
          <div
            className={`${gridView} text-left grid grid-cols-2 text-lg md:m-auto md:grid-cols-4 gap-6`}
          >
            <div className={"md:pb-0 pb-4"}>
              <div className={gridPrice}>1,900,000 AED</div>
              <div className={gridTitle}>Starting Price from</div>
            </div>
            <div className={"md:pb-0 pb-4"}>
              <div className={gridPrice}>1-4BR</div>
              <div className={gridTitle}>Available Units</div>
            </div>
            <div className={"md:pb-0 pb-4"}>
              <div className={gridPrice}>714</div>
              <div className={gridTitle}>Area from (sq. ft.)</div>
            </div>
            <div className={"md:pb-0 pb-4"}>
              <div className={gridPrice}>In progress</div>
              <div className={gridTitle}>Handover</div>
            </div>
          </div>
        </div>
        <div className={`${contentBlock} pt-10`}>
          <p>
            Oceano is a premium residential development by The Luxe Developers
            on Al Marjan Island in Ras Al Khaimah. Overlooking the Arabian Gulf,
            the complex will consist of 2 twin 17-storey towers, which will have
            free flowing glass facades with minimalist frames. The entrance of
            Oceano will wow occupants and guests alike with a dynamic cascading
            fountain and landscaping.
          </p>
        </div>
      </div>
    </>
  );
};

export default HeroArea;
