// extracted by mini-css-extract-plugin
export var contentDiv = "oceano-module--contentDiv--3frSw";
export var headingText = "oceano-module--headingText--2e9ym";
export var paraText = "oceano-module--paraText--1hvLO";
export var projectHeader = "oceano-module--projectHeader--2hXn9";
export var projectHeader_contentHeader = "oceano-module--projectHeader_contentHeader--28EK7";
export var projectHeader_contentHeader2 = "oceano-module--projectHeader_contentHeader2--19BzF";
export var headerTitle = "oceano-module--headerTitle--1qkcy";
export var projectHeader_imageHeader = "oceano-module--projectHeader_imageHeader--2umQk";
export var projectHeader_imageHeader2 = "oceano-module--projectHeader_imageHeader2--2ciui";
export var headerLogo = "oceano-module--headerLogo--1IwHD";
export var firstImage = "oceano-module--firstImage--p5oyI";
export var projectHeading = "oceano-module--projectHeading--1jqrR";
export var gridView = "oceano-module--gridView--1OaqV";
export var gridPrice = "oceano-module--gridPrice--hg8Gh";
export var gridTitle = "oceano-module--gridTitle--2Y5cP";
export var contentBlock = "oceano-module--contentBlock--3cqOV";
export var imageArea = "oceano-module--imageArea--3TXD7";
export var images = "oceano-module--images--3DK56";
export var expertMainDiv = "oceano-module--expertMainDiv--241nN";
export var expertRightBlock = "oceano-module--expertRightBlock--3mocd";
export var amenitiesList = "oceano-module--amenitiesList--sNe-s";
export var propertyList = "oceano-module--propertyList--1U08-";
export var propertyRightData = "oceano-module--propertyRightData--3m-Ok";
export var propertyLeftData = "oceano-module--propertyLeftData--3SgwN";
export var paymentDiv = "oceano-module--paymentDiv--3GDO8";
export var paymentGrid = "oceano-module--paymentGrid--20Ohe";
export var paymentTitle = "oceano-module--paymentTitle--3J74B";
export var paymentPer = "oceano-module--paymentPer--2vD_9";
export var contactBlock = "oceano-module--contactBlock--3y9bt";
export var contactHeader = "oceano-module--contactHeader--3hls7";
export var contactForm = "oceano-module--contactForm--16bV7";
export var formList = "oceano-module--formList--24M75";
export var imageBlock = "oceano-module--imageBlock--3h_e5";
export var content = "oceano-module--content--Wovxh";
export var afterBorder = "oceano-module--afterBorder--3B8l7";
export var formInput = "oceano-module--formInput--3ipgX";
export var round_right = "oceano-module--round_right--wmLco";
export var round_left = "oceano-module--round_left--3fR4Z";
export var w_100 = "oceano-module--w_100--1QLNp";
export var visible = "oceano-module--visible--b15aF";
export var hidden = "oceano-module--hidden--80pIc";
export var respmob = "oceano-module--respmob--rWVwZ";
export var modalDiv = "oceano-module--modalDiv--1oaRR";
export var closeButton = "oceano-module--closeButton--2TFwL";
export var popupForm = "oceano-module--popupForm--2vLT3";
export var checkBoxCss = "oceano-module--checkBoxCss--sra0z";
export var contentDivHeader = "oceano-module--contentDivHeader--T4ns-";
export var pageWidth = "oceano-module--pageWidth--3N9V4";
export var overlay = "oceano-module--overlay--LoDBx";