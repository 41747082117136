import * as React from "react";
import { BsPlusLg } from "react-icons/bs";
import { AiOutlineMinus } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { projectHeading, hidden, visible } from "./oceano.module.css";
import { useState } from "react";

import Collapsible from "react-collapsible";

let array = [
  {
    title: "Who is the developer of Oceano?",
    subTitle: "The creator of Oceano is The Luxe Developers.",
    show: true,
  },
  {
    title: "What is the address of Oceano?",
    subTitle: "Oceano is located on Al Marjan Island in Ras Al Khaimah.",
    show: false,
  },
  {
    title: "What are the offered unit types?",
    subTitle:
      "There are 1–4 bedroom apartments, 6 duplex penthouses and 2 sky villas.",
    show: false,
  },
  {
    title: "What is the completion date of Oceano?",
    subTitle:
      "For detailed information on the completion date of Oceano, please contact our real estate agents.",
    show: false,
  },
  {
    title: "What are the Payment Plans for Oceano?",
    subTitle:
      "You can take advantage of a 70/30 payment plan, with a 5% booking fee and a 15% down payment.",
    show: false,
  },
  {
    title:
      "How much does an apartment, a penthouse and a sky villa cost in Oceano?",
    subTitle:
      "As of Q2 2023, the starting cost for an apartment is AED 1.9M (USD 517K), whilst a duplex penthouse can be acquired for AED 18M (USD 4.9M). A luxury sky villa has a minimum price tag of AED 88M (USD 24M).",
    show: false,
  },
  {
    title: "Can I get residency if I buy property in Oceano?",
    subTitle:
      "Yes, you can obtain long-term residency, which applies to your family and domestic workers as well.",
    show: false,
  },
  {
    title: "What type of visa can I get when I buy a property?",
    subTitle:
      "If your investment is worth AED 2M (USD 545K) and above, you will be granted a 10-year Golden Visa.",
    show: false,
  },
  {
    title: "Can a foreigner buy property in Oceano?",
    subTitle:
      "Any nationality can acquire property in Oceano, as it is a freehold development.",
    show: false,
  },
  {
    title: "Is an apartment in Oceano a good investment?",
    subTitle:
      "Real estate in Oceano is an excellent investment, providing you with the opportunity to earn decent rental profit. As of May 2023, the average ROI on Al Marjan Island is 6%, whilst the starting rental price is set at AED 30K (USD 8K) per annum.",
    show: false,
  },
  {
    title: "Is Oceano a good place for living in Ras Al Khaimah?",
    subTitle:
      "Oceano will be perfect for single individuals, couples and families who wish to enjoy a resort-like living with easy access to social facilities and the international airport.",
    show: false,
  },
  {
    title: "What are the pros and cons of living on Al Marjan Island?",
    subTitle:
      "Al Marjan Island is located a 10-minute drive away from prestigious medical and academic institutions. These include RAK Medical Center Al Jazeera, RAK Medical Center Al Hamra and RAK Academy — British School Al Hamra — BSH, among others.",
    show: false,
  },
];

const Eminity = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <>
      <div className={projectHeading}>
        <h2>FAQ</h2>
      </div>
      <div>
        {array.map((item, index) => {
          return (
            <div
              className={"border-b border-t border-primary-lightestGray py-4"}
            >
              <Collapsible
                className="md:text-xl max-w-xs md:max-w-none hover:text-primary-bnwdarkgold font-bold text-lg"
                trigger={item.title}
                open={activeIndex === index}
                onOpening={() => setActiveIndex(index)}
              >
                <div className="mt-3">{item.subTitle}</div>
              </Collapsible>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Eminity;
