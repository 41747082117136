import { modal } from "../../styles/main.module.css";
import React, { useContext, useState } from "react";
import {
  respmob,
  modalDiv,
  closeButton,
  popupForm,
  formInput,
} from "./oceano.module.css";
import { FaRegTimesCircle } from "react-icons/fa";
import { addForm, apiUrl } from "../../action";
import { siteUrl } from "../_utils";
import { input } from "../aziziComp/form.module.css";

let showBlock = false;

const ModalForm = ({
  countryISO,
  formName,
  downloadType,
  onClose,
  brochureType,
}) => {
  const [pageTitle, setPageTitle] = React.useState("");
  const [btnTitle, setBtnTitle] = React.useState("");
  const [obj, setObj] = useState({});

  React.useEffect(() => {
    if (window.location.href.indexOf("formModal") > -1) {
      showBlock = true;
    }
  }, []);
  React.useEffect(() => {
    if (downloadType == "brochure") {
      setPageTitle("Fill your details to download the brochure");
      setBtnTitle("DOWNLOAD BROCHURE");
    }
    if (downloadType == "floor") {
      setPageTitle("Fill your details to download the floor plans");
      setBtnTitle("DOWNLOAD FLOOR PLANS");
    }
    if (downloadType == "payment") {
      setPageTitle("Fill your details to download the payment plans");
      setBtnTitle("DOWNLOAD PAYMENT PLANS");
    }
    if (downloadType == "interior") {
      setPageTitle("Fill your details to see the Interior Pdf");
      setBtnTitle("SEE INTERIOR DESIGN");
    }
  }, [downloadType]);

  const [loader, setLoader] = useState();
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    obj.formType = "download";
    obj.formName = "oceano";
    console.log(obj , "djdsgfahysdvg");

    let x = await addForm(obj);

    if (!x.error) {
        if (x.downloadId) {
          let downloadLink = `${apiUrl}download?sid=${x.downloadId}`;
          let link = document.createElement("a");
          document.body.appendChild(link);
          link.setAttribute("type", "hidden");
          link.href = downloadLink;
          //link.download = `${props.downloadType}.pdf`
          link.click();
          document.body.removeChild(link);
          document.getElementById("downloadbrochure").style.display = "none";
        } else {
          setTimeout(() => {
            window.location.href = `${siteUrl}thankuPage`;
          }, 1000);
        }
      } else {
      }
  };

  return (
    <div id={"formModal"} className={showBlock ? "block" : "hidden"}>
      <div className={modal}>
        <div className={modalDiv}>
          <div className={`bg-white rounded-md ${respmob}`}>
            <div className={""}>
              <div
                className={"flex justify-center bg-primary-bnwdarkgold"}
                style={{
                  padding: "25px 40px",
                  fontSize: "21px",
                  textAlign: "center",
                  color: "white",
                  //   backgroundColor: "#02b2ee",
                }}
              >
                {/*className="relative float-right -right-7 -top-12"*/}
                <div>Download Brochure</div>
                <a
                  href={"#"}
                  className={closeButton}
                  onClick={() => {
                    onClose();
                    document.getElementById("formModal").style.display = "none";
                  }}
                >
                  <FaRegTimesCircle className="{'white'} items-center text-white text-3xl pb-2  " />
                </a>
              </div>

              <div className={popupForm}>
                <form onSubmit={onSubmit} noValidate={false}>
                  <div className={"mb-4 border-b"}>
                    <input
                      required
                      onChange={(e) => {
                        setObj({
                          ...obj,
                          name: e.target.value,
                        });
                      }}
                      name={"name"}
                      placeholder={"Name"}
                      className={"p-2 w-full"}
                    />
                  </div>
                  <div className={"border-b mb-4"}>
                    <input
                      required
                      onChange={(e) => {
                        setObj({
                          ...obj,
                          email: e.target.value,
                        });
                      }}
                      type={"email"}
                      name={"email"}
                      placeholder={"Email"}
                      className={"p-2 w-full"}
                    />
                  </div>
                  <div className={"border-b mb-4"}>
                    <input
                      required
                      onChange={(e) => {
                        setObj({
                          ...obj,
                          mobile: e.target.value,
                        });
                      }}
                      name={"mobile"}
                      placeholder={"Mobile"}
                      className={"p-2 w-full"}
                    />
                  </div>
                  <div className={"border-b mb-4"}>
                    <textarea
                      onChange={(e) => {
                        setObj({
                          ...obj,
                          message: e.target.value,
                        });
                      }}
                      placeholder={"Message"}
                      id="message"
                      name="message"
                      rows="1"
                      className={"p-2 w-full"}
                    />
                  </div>

                  <div className={"flex items-start text-sm"}>
                    <div className={"pt-1 mr-2 text-white w-5 md:w-2"}>
                      <input
                        required={true}
                        type="checkbox"
                        id={"policy"}
                      ></input>
                    </div>
                    <div className={"p-0.5"}>
                      I accept the terms of the User Agreement and Privacy
                      Policy
                    </div>
                  </div>
                  <div className={"flex items-start text-sm"}>
                    <div className={"pt-1 mr-2 text-white w-8 md:w-2"}>
                      <input
                        required={true}
                        type="checkbox"
                        id={"offers"}
                      ></input>
                    </div>
                    <div className={"p-0.5"}>
                      I agree to receive information about offers, deals and
                      services from this website (optional)
                    </div>
                  </div>

                  <div className={"mt-4 text-center"}>
                    <button
                      type={"submit"}
                      className="rounded-lg text-base px-9 py-2 h-10  hover:oceanoDarkColor hover:text-white bg-primary-bnwdarkgold text-white"
                      style={{ margin: "auto" }}
                      disabled={loader}
                    >
                      {loader ? "WAIT.." : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalForm;
