// extracted by mini-css-extract-plugin
export var mainSection = "heroArea-module--mainSection--1fECO";
export var headerSection = "heroArea-module--headerSection--21YM7";
export var topSection = "heroArea-module--topSection--g4kJA";
export var bottomUnderLine = "heroArea-module--bottomUnderLine--3z060";
export var footerList = "heroArea-module--footerList--1aCn1";
export var heroText = "heroArea-module--heroText--1nRy5";
export var crestList = "heroArea-module--crestList--3J43d";
export var sobhaHeading = "heroArea-module--sobhaHeading--3Razi";
export var sliderFooterContainer = "heroArea-module--sliderFooterContainer--aY81K";
export var sliderFooter = "heroArea-module--sliderFooter--1iyBc";
export var videoContainer = "heroArea-module--videoContainer--i4m43";
export var gridBox = "heroArea-module--gridBox--3m_TC";
export var firstBlock = "heroArea-module--firstBlock--2qWn8";
export var gridBox2 = "heroArea-module--gridBox2--1Whci";
export var area = "heroArea-module--area--w02qX";
export var price = "heroArea-module--price--1uR02";
export var downloadBtn = "heroArea-module--downloadBtn--2kGZM";
export var firstGrid = "heroArea-module--firstGrid--AZ_tf";
export var gridText = "heroArea-module--gridText--1Wz9k";
export var minTextClass = "heroArea-module--minTextClass--2C2mp";
export var addressBlock = "heroArea-module--addressBlock--1jUFE";
export var formBox = "heroArea-module--formBox--3pD5n";
export var roundImage = "heroArea-module--roundImage--2O1mO";