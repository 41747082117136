import { topBarIcon, phoneIcon, topBarItem, aziziHeader } from '../styles/main.module.css'
import { FiPhoneCall } from 'react-icons/fi'
import { GoMail } from 'react-icons/go'
import { FaWhatsapp } from 'react-icons/fa'
import *  as React from 'react'
import { useState } from 'react'
import { contentDivHeader } from '../components/oceano/oceano.module.css'

const NavHeader = ({logoComp}) => {

    const [phoneNumber] = useState('+971549994155')
    const [phoneNumberWithoutPlus] = useState('971549994155')
    const [phoneNumberText] = useState('+971 54 999 4155')

    return (<nav>
        <div className={contentDivHeader}>
            <div className={`p-3  mx-auto justify-between flex items-center overflow-hidden ${aziziHeader}`}>
                <div className={'sm:pl-0'}>
                    {logoComp}
                </div>
                <div className={'hidden sm:flex w-'}>
                    <div className={topBarItem}>
                        <a className={'hover:text-hover-default font-medium text-base'} href={`tel:${phoneNumber}`} rel="noopener">
                            <FiPhoneCall className={`${phoneIcon} inline`}/> Let's Talk {phoneNumberText}</a>
                    </div>
                    <div className={topBarItem}>

                        <a className={'hover:text-hover-default font-medium text-base'}
                           href={`https://api.whatsapp.com/send?phone=${phoneNumberWithoutPlus}&amp;text=Hi,`}
                           target="_blank"
                           rel="noopener" tabIndex="0"> <FaWhatsapp className={`${topBarIcon} text-primary-green inline`}/> Sales &
                            Support
                        </a>
                    </div>
                    {/*   <div className={topBarItem}>
          <GoMail className={topBarIcon}/>
          <a className={'hover:text-hover-default'} href={'#'} rel="noopener">Register Interest</a>
        </div>*/}
                </div>
                <div className={'flex space-x-5 items-center justify-between sm:hidden'}>
                    <a className={'hover:text-hover-default'} href={`tel:${phoneNumber}`} rel="noopener">
                        <FiPhoneCall className={`text-xl text-gray-600 `}/>
                    </a>

                    <a className={'hover:text-hover-default'}
                       href={`https://api.whatsapp.com/send?phone=${phoneNumberWithoutPlus}&amp;text=Hi,`}
                       target="_blank"
                       rel="noopener" tabIndex="0">
                        <FaWhatsapp className={`${topBarIcon} text-primary-green text-2xl`}/>
                    </a>
                </div>
            </div>
        </div>
    </nav>)
}

export default NavHeader
