import * as React from "react";
import {} from "../../components/sobhaVilla1Comp/heroArea.module.css";
import NavHeader from "../../components/oceanoNavbar";
import HeroArea from "../../components/oceano/heroArea";
import GalleryView from "../../components/oceano/galleryView";
import Amenties from "../../components/oceano/amenties";
import PrivatePool from "../../images/oceano/banner02-01.jpg";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../../components/App/SEO";
import { siteUrl } from "../../components/_utils";
import { useEffect, useState } from "react";
import { getCountry } from "../../action";
import { useScrollTracker } from "react-scroll-tracker";
import ReactGA from "react-ga";
import { contentDiv } from "../../components/oceano/oceano.module.css";
// import Footer from "../../components/sobhaVilla2Comp/footer";
import Footer from "../../components/damacLagoonsMalta/footer";
import Map from "../../components/oceano/map";
import EconomicAppeal from "../../components/oceano/economicAppeal";
import ModalPage from "../../components/oceano/formModal";

const shortNumber = require("short-number");

let dollarAmount = 265450;

const SobhaVilla1 = ({ data }) => {
  const [countryISO, setCountryISO] = useState("ae");
  const [short, setShort] = useState(shortNumber(dollarAmount));
  const [symbol, setSymbol] = useState("$");
  const [downloadType, setDownloadFormType] = useState(null);

  useEffect(() => {
    getCountry({ amount: dollarAmount }).then((data) => {
      console.log(data);
      if (data.short) {
        setShort(data.short);
      }
      if (data.symbol) {
        setSymbol(data.symbol);
      }
      if (data.iso) {
        setCountryISO(data.iso.toLowerCase());
      }
    });
  }, []);

  if (typeof window !== "undefined") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScrollTracker([10, 15, 25, 50, 75, 100], ({ scrollY }) => {
      ReactGA.event({
        category: "scroll",
        action: scrollY,
      });
    });
  }

  const logoComp = (
    <StaticImage
      className="w-32 md:w-56 lg:w-56  align-middle"
      placeholder="tracedSVG"
      src={"../../images/oceano/oceanoLogo.png"}
      alt={"Oceano"}
    />
  );

  const titleParams = {
    title: `Oceano | ${siteUrl}`,
    ogTitle: `Oceano | ${siteUrl}`,

    description:
      "Oceano is a premium residential development by The Luxe Developers on Al Marjan Island in Ras Al Khaimah.",
    ogDescription:
      "Oceano is a premium residential development by The Luxe Developers on Al Marjan Island in Ras Al Khaimah",
    keywords: "70/30 PAYMENT PLAN",
    secureImageUrl: `${PrivatePool}`,
    ogImage: `${PrivatePool}`,
    ogImageAlt: "Oceano",

    orUrl: `${siteUrl}al-marjan-island/oceano/`,
    pageLink: `${siteUrl}al-marjan-island/oceano`,
    canonicalRef: `${siteUrl}al-marjan-island/oceano`,
  };

  return (
    <>
      <SEO data={titleParams} />
      <ModalPage
        downloadType={downloadType}
        onClose={() => {}}
        formName={"oceano"}
        countryISO={countryISO}
      />
      <div className="container px-5">
        <NavHeader logoComp={logoComp} />

      </div>
        <div className={`${contentDiv}`}>
          <div className="grid grid-cols-12 gap-1">
            <div className="col-span-12">
              <HeroArea />
            </div>
          </div>
        </div>
      <div className="overflow-hidden	">
        <div className="container px-5 my-16">
          <GalleryView />
        </div>
      </div>
      <div className="container px-5 mb-16">
        <div className={`${contentDiv}`}>
          <div className="grid grid-cols-12 gap-1">
            <div className="col-span-12">
              <Amenties />
            </div>
          </div>
        </div>
      </div>
      <Map />
      <div className="container px-5">
        <div className={`${contentDiv}`}>
          <div className="grid grid-cols-12 gap-1">
            <div className="col-span-12">
              <EconomicAppeal />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SobhaVilla1;
