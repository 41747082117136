import * as React from 'react'
import {MdArrowBackIosNew, MdArrowForwardIos} from 'react-icons/md'
import {StaticImage} from 'gatsby-plugin-image'
import Slider from 'react-slick'
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs'

const Map = (props) => {

    return (<>
        <div className="mapouter mt-5" style={{position: 'relative', textAlign: 'right', height: '100%', width: '100%'}}>
            <div className="gmap_canvas" style={{
                overflow: 'hidden',
                background: 'none!important',
                height: '500px',
                width: '100%'
            }}>
                <iframe width="100%" height="100%" id="gmap_canvas"
                        src="https://maps.google.com/maps?q=33rd Floor, Ubora Tower, Business Bay, Dubai, UAE&t=&z=10&ie=UTF8&iwloc=&output=embed"
                        frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
            </div>
        </div>

    </>)
}


export default Map
