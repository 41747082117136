import { input } from "../../components/aziziComp/form.module.css";
import { formList, formInput, checkBoxCss, Btn_hvr } from "./oceano.module.css";
import * as React from "react";
import { useForm } from "react-hook-form";
import { addForm, apiUrl } from "../../action";
import { siteUrl } from "../_utils";
import { ImSpinner } from "react-icons/im";
import { loaderText } from "../fiveJBR/form.module.css";

const FormLayout = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (data) => {
    console.log(data);

    if (!data.name) {
      alert("Enter Name");
      return false;
    }
    if (!data.mobile) {
      alert("Fill Phone");
      return false;
    }

    if (!data.email) {
      alert("Fill Email Id");
      return false;
    }

    if (!data.policy) {
      alert("Fill Required Fields");
      return false;
    }
    if (!data.offers) {
      alert("Fill Required Fields");
      return false;
    }

    // ReactGA.event({
    //     category: 'BtnClick',
    //     action: 'sliderButtonClick',
    // })

    // if (props.formType) {
    //     objj.formType = props.formType
    // }
    // if (props.downloadType) {
    //     objj.downloadType = props.downloadType
    // }
    // if (props.brochureType) {
    //     objj.brochureType = props.brochureType
    // }

    setLoading(true);
    data.formName = "oceano";
    data.formType = "download";
    let x = await addForm(data);
console.log(x , "fsdkjjfadyfav")
    
    //window.gtag('event', 'click', {send_to: ['AW-368264267/97v8CICmr4wDEMuIza8B']})
    // window.gtag("event", "click", { send_to: "AW-CONVERSION_ID", ...data })

    setLoading(false);

    if (!x.error) {
      if (x.downloadId) {
        let downloadLink = `${apiUrl}download?sid=${x.downloadId}`;
        let link = document.createElement("a");
        document.body.appendChild(link);
        link.setAttribute("type", "hidden");
        link.href = downloadLink;
        //link.download = `${props.downloadType}.pdf`
        link.click();
        document.body.removeChild(link);
        // document.getElementById("downloadbrochure").style.display = "none";
      } else {
        setTimeout(() => {
          window.location.href = `${siteUrl}thankuPage`;
        }, 1000);
      }
    } else {
    }
    
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate={false}>
        <div className={`grid grid-cols-12 gap-2`}>
          <div className={"md:col-span-4 col-span-12"}>
            <input
              required={true}
              className={formInput}
              type="text"
              placeholder="Name"
              id={"name"}
              {...register("name")}
            />
          </div>
          <div className={"md:col-span-4 col-span-12 text-black"}>
            <input
              required={true}
              className={formInput}
              type="number"
              placeholder="Phone"
              id={"mobile"}
              {...register("mobile")}
            />

            {/*<div className={`border border-primary-gray border-opacity-20 rounded ${telinputContainer}`}>*/}
            {/*    <ReactFlag countryISO={props.countryISO} footerFlag={false} flagValue={(value) => {*/}
            {/*        if (value && value.mobile) {*/}
            {/*            setMobile(value.mobile)*/}
            {/*            setCode(value.code)*/}
            {/*        } else {*/}
            {/*            setMobile('')*/}
            {/*            setCode('')*/}
            {/*        }*/}
            {/*    }}/>*/}
            {/*</div>*/}
          </div>
          <div className={"md:col-span-4 col-span-12"}>
            <input
              required={true}
              className={formInput}
              type="email"
              placeholder="Email"
              id={"email"}
              {...register("email")}
            />
          </div>
          <div className={"md:col-span-12 col-span-12"}>
            <textarea
              {...register("message")}
              className={formInput}
              placeholder={"Message"}
              id="message"
              name="message"
              rows="4"
              cols="50"
            />
          </div>
          <div className={"md:col-span-12 col-span-12"}>
            <div className={checkBoxCss}>
              <label>
                <input
                  required={true}
                  type="checkbox"
                  id={"policy"}
                  {...register("policy")}
                />{" "}
                I accept the terms of the User Agreement and Privacy Policy
              </label>
            </div>
            <div className={checkBoxCss}>
              <label>
                <input
                  type="checkbox"
                  required={true}
                  id={"offers"}
                  {...register("offers")}
                />{" "}
                I agree to receive information about offers, deals and services
                from this website (optional)
              </label>
            </div>

            <div className={"mt-6 text-center md:text-right"}>
              <button
                style={{
                  flex: 1,
                  alignContent: "center",
                }}
                className={`text-base px-9 py-1 h-10 border-2 border-bnwdarkgold hover:bg-white bg-primary-bnwdarkgold hover:text-primary-bnwdarkgold text-white ${Btn_hvr}`}
                type="submit"
              >
                <span className={loaderText}>
                  {loading && <ImSpinner className={"icon-spin "} />}
                </span>

                <span>PROPERTY INQUIRY</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormLayout;
