import * as React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {
    formList,
    contactHeader,
    contactBlock, contactForm, round_left, round_right, imageBlock, content, afterBorder
} from './oceano.module.css'
import {MdOutlineLocationOn} from "react-icons/md";
import {FiPhoneCall} from "react-icons/fi";
import {AiOutlineMail} from "react-icons/ai";
import image1 from '../../images/oceano/mail.svg'
import image2 from '../../images/oceano/phone.svg'
import image3 from '../../images/oceano/location.svg'
import FormComp from "./form";


const ContactForm = ({countryISO, short, symbol}) => {
    return (
        <>
            <div className={`${contactBlock} md:flex md:flex-row my-20 shadow-lg`}>
                <div className={`${contactHeader} ${round_left}`}>
                    <h2 className={'text-2xl font-bold;'}>Contact Us</h2>
                    <ul className={`${formList} leading-6 text-sm text-white`}>
                        <li className={`font-normal pt-8`}>
                            <span className={imageBlock}><img src={image1}/></span>
                            <span className={content}><a target={'_blank'}
                                                         href={'mailto:info@bnwdubai.com'}>info@bnwdubai.com</a></span>
                        </li>
                        <div className={afterBorder}>

                        </div>
                        <li className={`font-normal`}>
                            <span className={imageBlock}> <img src={image2}/></span>
                            <span className={content}> <a target={'_blank'} href={'https://wa.me/971549994155'}
                                                          className={'w-4/5'}>+971 54 999 4155
                            </a></span>
                        </li>
                        <div className={afterBorder}>

                        </div>
                        <li className={'font-normal'}>
                            <span className={imageBlock}><img src={image3}/></span>
                            <span className={content}><a className={'w-4/5'}>33rd Floor, Ubora Tower,<br/>
                                Business Bay, Dubai, UAE
                            </a></span>
                        </li>
                    </ul>


                </div>
                <div className={`${contactForm} ${round_right}`}>
                    <h2 className={'text-2xl font-bold md:text-left text-center'}>Get A Free Consultation</h2>
                    <div className={`text-lg md:text-left text-center mb-4`}>
                        Ready for your new home?
                    </div>

                    <FormComp/>

                </div>

            </div>


        </>
    )
}

export default ContactForm
