import * as React from "react";
import { BsDashLg } from "react-icons/bs";
import { videoContainer } from "../crest/heroArea.module.css";
import { useRef } from "react";
import useIntersectionObserver from "@react-hook/intersection-observer";
import {
  contentBlock,
  amenitiesList,
  propertyList,
  propertyLeftData,
  propertyRightData,
  projectHeading,
  paymentPer,
  paymentTitle,
  paymentDiv,
  paymentGrid,
  pageWidth,
} from "./oceano.module.css";
import ExpertBlock from "./expertBlock";
import { StaticImage } from "gatsby-plugin-image";

const LazyIframe = ({ url, title }) => {
  const containerRef = useRef();
  const lockRef = useRef(false);
  const { isIntersecting } = useIntersectionObserver(containerRef);
  if (isIntersecting) {
    lockRef.current = true;
  }
  return (
    <div
      style={{
        width: "100%",
      }}
      ref={containerRef}
    >
      {lockRef.current && (
        <iframe
          title={title}
          style={{
            bottom: 0,
            height: "100%",
            left: 0,
            position: "absolute",
            right: 0,
            top: 0,
            width: "100%",
          }}
          src={url}
          frameborder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen="allowfullscreen"
        ></iframe>
      )}
    </div>
  );
};

const Eminity = (props) => {
  return (
    <>
      <div className={`${contentBlock} mt-6`}>
        <p>
          The complex will feature 206 residential units, namely 1–4 bedroom
          apartments, 6 duplex penthouses and 2 sky villas. The property sizes
          range from 714 sq. ft to 22,128 sq. ft. The interiors of Oceano
          apartments will have a futuristic concept with smart technology
          integrated throughout.
        </p>
      </div>

      <div
        className={
          "grid grid-cols-12 md:gap-x-10 gap-y-4 md:gap-y-0 md:mt-14 mt-8"
        }
      >
        {/* <div className={'md:col-span-7 col-span-12 rounded-lg'}>
                <div className={`${videoContainer} rounded-lg md:h-80 h-full `}>
                    <LazyIframe url={"https://www.youtube.com/embed/B20B3Nnss6I"} title={"Oceano"}/>
                </div>
            </div> */}
        <div className={"col-span-12"}>
          <p className={"mb-6 text-sm"}>
            The high-end complex will be equipped with a range of unrivalled
            amenities for a comfortable living environment. These will include:
          </p>
          <div className="grid grid-cols-12">
            <div className="md:col-span-6 col-span-12">
              <ul className={`${amenitiesList} leading-6 text-sm text-black`}>
                <li>
                  <BsDashLg />
                  Private beach
                </li>
                <li>
                  <BsDashLg />
                  Jetty
                </li>
                <li>
                  <BsDashLg />
                  Spa
                </li>
                <li>
                  <BsDashLg />
                  Gym
                </li>
                <li>
                  <BsDashLg />
                  Swimming pool with Arabian Gulf views
                </li>
              </ul>
            </div>
            <div className="md:col-span-6 col-span-12">
              <ul className={`${amenitiesList} leading-6 text-sm text-black`}>
                <li>
                  <BsDashLg />
                  Private salon
                </li>
                <li>
                  <BsDashLg />
                  Cigar lounge
                </li>
                <li>
                  <BsDashLg />
                  Informal breakout pool and terrace area
                </li>
                <li>
                  <BsDashLg />
                  Retail level
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={"md:pt-20 pt-10 md:pb-0 pb-10"}>
        <ExpertBlock />
      </div>
      <div className={pageWidth}>
        <div className={projectHeading}>
          <h2>Property Information</h2>
        </div>
        <div className={"grid grid-cols-12 md:gap-10 gap-0"}>
          <ul className={"md:col-span-7 col-span-12"}>
            <li className={propertyList}>
              <span className={propertyLeftData}>Location</span>
              <span className={`${propertyRightData} text-primary-bnwdarkgold`}>
                Al Marjan Island, Ras Al Khaimah, UAE
              </span>
            </li>

            <li className={propertyList}>
              <span className={propertyLeftData}>Status of Project</span>
              <span className={propertyRightData}>In progress</span>
            </li>

            <li className={propertyList}>
              <span className={propertyLeftData}> Available Units</span>
              <span className={propertyRightData}>
                1-3BR Apartments, 4BR Penthouses
              </span>
            </li>

            <li className={propertyList}>
              <span className={propertyLeftData}>Total Amount of Units</span>
              <span className={propertyRightData}>206</span>
            </li>
          </ul>

          <ul className={"md:col-span-5 col-span-12"}>
            <li className={propertyList}>
              <span className={propertyLeftData}>Developer</span>
              <span className={`${propertyRightData} text-primary-bnwdarkgold`}>
                The Luxe Developers
              </span>
            </li>

            <li className={propertyList}>
              <span className={propertyLeftData}>Type of Project</span>
              <span className={propertyRightData}>Residential Tower</span>
            </li>

            <li className={propertyList}>
              <span className={propertyLeftData}> Area from</span>
              <span className={propertyRightData}>714 sq. ft.</span>
            </li>

            <li className={propertyList}>
              <span className={propertyLeftData}>Payment Plan</span>
              <span className={propertyRightData}>70/30</span>
            </li>
          </ul>
        </div>
      </div>
      <div className={projectHeading}>
        <h2>Property Prices</h2>
      </div>
      <div className={`${contentBlock}`}>
        <p>
          As of Q2 2023, the initial cost for an apartment is AED 1.9M (USD
          517K), whilst a duplex penthouse can be acquired for AED 18M (USD
          4.9M). A luxury sky villa is on offer for a minimum of AED 88M (USD
          24M).
        </p>
        <p>*The price is valid for 2023</p>
      </div>

      <div className={`mb-3 ${pageWidth}`}>
        <div className={projectHeading}>
          <h2>Payment Plan</h2>
        </div>
        <div className={`${paymentDiv} grid grid-cols-12 mb-5 md:mb-0`}>
          <div className={"md:col-span-12 col-span-12"}>
            <div className={paymentGrid}>
              <div>
                <div className={paymentPer}>5%</div>
                <div className={paymentTitle}>Booking Amount</div>
              </div>
              <div>
                <div className={paymentPer}>15%</div>
                <div className={paymentTitle}>Down payment</div>
              </div>
              <div>
                <div className={paymentPer}>6%</div>
                <div className={paymentTitle}>6 Months From Sale Date</div>
              </div>
              <div>
                <div className={paymentPer}>6%</div>
                <div className={paymentTitle}>9 Months From Sale Date</div>
              </div>
              <div>
                <div className={paymentPer}>6%</div>
                <div className={paymentTitle}>12 Months From Sale Date</div>
              </div>
              <div>
                <div className={paymentPer}>6%</div>
                <div className={paymentTitle}>15 Months From Sale Date</div>
              </div>
              <div>
                <div className={paymentPer}>6%</div>
                <div className={paymentTitle}>18 Months From Sale Date</div>
              </div>
              <div>
                <div className={paymentPer}>6%</div>
                <div className={paymentTitle}>21 Months From Sale Date</div>
              </div>
              <div>
                <div className={paymentPer}>7%</div>
                <span className={paymentTitle}>24 Months From Sale Date</span>
              </div>
              <div>
                <div className={paymentPer}>7%</div>
                <div className={paymentTitle}>27 Months From Sale Date</div>
              </div>
              <div>
                <div className={paymentPer}>30%</div>
                <div className={paymentTitle}>On Handover</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          "grid grid-cols-1 sm:grid-cols-2 md:gap-10 gap-10 items-center md:mt-20 lg:mt-10"
        }
      >
        <div>
          <div className={projectHeading}>
            <h2>Location</h2>
          </div>
          <p className={"text-sm leading-6"}>
            When residing in Oceano, you will be able to enjoy ultimate,
            resort-like living thanks to an array of F&B venues and luxury
            resorts within walking distance. These include The Market, Neo Sky
            Bar, Movenpick Resort Al Marjan Island and DoubleTree by Hilton
            Resort & Spa Marjan Island, to name a few. For quick grocery
            shopping, you can stop at ZOOM, a few steps away.
          </p>
          <p className={"text-sm my-4"}>
            Within about a 10-minute drive, the following facilities can be
            found:
          </p>
          <ul className={`${amenitiesList} leading-6 text-sm text-black`}>
            <li>
              <BsDashLg />
              RAK Medical Center Al Jazeera
            </li>
            <li>
              <BsDashLg />
              RAK Medical Center Al Hamra
            </li>
            <li>
              <BsDashLg />
              Al Hamra Mall
            </li>
            <li>
              <BsDashLg />
              Leptis Hypermarket Al Hamra
            </li>
            <li>
              <BsDashLg />
              RAK Academy — British School Al Hamra — BSH
            </li>
          </ul>
        </div>
        <div>
          <div className={"flex flex-col"}>
            <div className={"flex justify-center"}>
              <StaticImage
                style={{
                  width: "70%",
                  objectFit: "contain",
                  borderRadius: "10px",
                }}
                srl_gallery_image="true"
                src={"../../images/oceano/location1.jpg"}
              />
            </div>

            <div className={"flex mt-1"}>
              <div className={"mr-1"} style={{ width: "600px" }}>
                <StaticImage
                  style={{ borderRadius: "10px", objectFit: "contain" }}
                  srl_gallery_image="true"
                  src={"../../images/oceano/location2.jpg"}
                />
              </div>
              <div>
                <StaticImage
                  className={"md:h-60 h-40"}
                  style={{ borderRadius: "10px", objectFit: "contain" }}
                  srl_gallery_image="true"
                  src={"../../images/oceano/location3.jpg"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Eminity;
