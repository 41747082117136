import * as React from 'react'
import {MdArrowBackIosNew, MdArrowForwardIos} from 'react-icons/md'
import {StaticImage} from 'gatsby-plugin-image'
import Slider from 'react-slick'
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs'

import {imageArea, images} from "./oceano.module.css";
import tile1 from '../../images/oceano/sliderImage1.png'
import tile2 from '../../images/oceano/sliderImage2.png'
import tile3 from '../../images/oceano/sliderImage3.png'
import tile4 from '../../images/oceano/sliderImage4.png'
import tile5 from '../../images/oceano/sliderImage5.png'
import tile6 from '../../images/oceano/sliderImage6.png'
import tile7 from '../../images/oceano/sliderImage7.png'

function SampleNextArrow(props) {
    const {className, style, onClick} = props
    return (
        <div
            className={'testing'}
            style={{right: '120px', color: 'black'}}
            onClick={onClick}
        >
            <BsChevronRight/>
        </div>
    )
}

function SamplePrevArrow(props) {
    const {className, style, onClick} = props
    return (
        <div
            className={'testing'}
            style={{left: '20px', color: 'black'}}
            onClick={onClick}
        >
            <BsChevronLeft/>
        </div>
    )
}

const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    arrows: true,
    centerMode: true,
    prevArrow: <SamplePrevArrow/>,
    nextArrow: <SampleNextArrow/>,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1008,
            settings: {
                arrows: false,
                prevArrow: <SamplePrevArrow/>,
                nextArrow: <SampleNextArrow/>,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
}
const Eminity = (props) => {

    return (
        <div className={imageArea} id={'oceanSlickSlider'}>
            <Slider {...settings}>
                <div className={'md:pr-2 pr-2'}>
                    <a style={{display: 'block'}} target={'_blank'} href={tile1}>
                        <StaticImage
                            className={images}
                            srl_gallery_image="true"
                            src={'../../images/oceano/sliderImage1.png'}
                        />
                    </a>
                </div>
                <div className={'md:pr-2 pr-2'}>
                    <a style={{display: 'block'}} target={'_blank'} href={tile2}>
                        <StaticImage
                            className={images}
                            srl_gallery_image="true"
                            src={'../../images/oceano/sliderImage2.png'}
                        />
                    </a>
                </div>
                <div className={'md:pr-2 pr-2'}>
                    <a style={{display: 'block'}} href={tile3} target={'_blank'}>
                        <StaticImage
                            srl_gallery_image="true"
                            className={images}
                            src={'../../images/oceano/sliderImage3.png'}
                        />
                    </a>
                </div>
                <div className={'md:pr-2 pr-2'}>
                    <a style={{display: 'block'}} href={tile4} target={'_blank'}>
                        <StaticImage
                            srl_gallery_image="true"
                            className={images}
                            src={'../../images/oceano/sliderImage4.png'}
                        />
                    </a>
                </div>
                <div className={'md:pr-2 pr-2'}>
                    <a style={{display: 'block'}} href={tile5} target={'_blank'}>
                        <StaticImage
                            srl_gallery_image="true"
                            className={images}
                            src={'../../images/oceano/sliderImage5.png'}
                        />
                    </a>
                </div>
                <div className={'md:pr-2 pr-2'}>
                    <a style={{display: 'block'}} href={tile6} target={'_blank'}>
                        <StaticImage
                            srl_gallery_image="true"
                            className={images}
                            src={'../../images/oceano/sliderImage6.png'}
                        />
                    </a>
                </div>
                <div className={'md:pr-2 pr-2'}>
                    <a style={{display: 'block'}} href={tile7} target={'_blank'}>
                        <StaticImage
                            srl_gallery_image="true"
                            className={images}
                            src={'../../images/oceano/sliderImage7.png'}
                        />
                    </a>
                </div>


            </Slider>
        </div>
    )
}

export default Eminity
