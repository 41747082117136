import React from "react";

import {
  expertMainDiv,
  expertLeftBlock,
  expertRightBlock,
  subTitle,
} from "./oceano.module.css";
import { loaderText } from "../fiveJBR/form.module.css";
import { ImSpinner } from "react-icons/im";
import { FaWhatsapp } from "react-icons/fa";

let DownloadAndroidApps = (props) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <>
      <div className={expertMainDiv}>
        <div className={expertLeftBlock}>
          <div className={`text-2xl md:text-4xl text-primary-bnwdarkgold font-bold mb-2`}>
            Our Expert Will Help You
          </div>
          <div className={`${subTitle} text-sm`}>
            Feel Free to Contact Us at Any Time, We Are Online 24/7
          </div>
        </div>
        <div className={expertRightBlock}>
          <div className={"text-center mb-2 md:mb-0"}>
            <button
              disabled={loading}
              className={
                "w-44 text-base px-9 py-2 h-10 bg-primary-green h-10 text-primary-transwhite hover:bg-bnwdarkgold"
              }
              type="submit"
            >
              <span>
                {" "}
                <FaWhatsapp className={"text-base mr-2 mb-1 inline"} /> WhatsApp
              </span>
            </button>
          </div>
          <div className={"text-center"}>
            <button
            onClick={() => {
              document.getElementById("formModal").style.display = "block";
            }}
              disabled={loading}
              style={{ flex: 1, alignContent: "center" }}
              className={
                "w-44 border-2 border-bnwdarkgold hover:bg-white bg-primary-bnwdarkgold hover:text-primary-bnwdarkgold h-10 text-base px-9 h-10 text-white flex justify-center items-center"
              }
              type="submit"
            >
              <span>Contact Us</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default DownloadAndroidApps;
